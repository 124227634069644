import axios from '@/api/request.js'
import qs from 'qs'

const baseUrl = process.env.VUE_APP_DOMAIN_URL;

// 登录
const loginIn = data => {
  return axios.post(baseUrl + '/athena-consumer/athenaUserDO/loginByAccount', qs.stringify(data))
}

//根据tenantCode查询租户信息
const getChamberLanguage = data => {
    return axios.post(baseUrl + "/athena-consumer/controlCenterChamberDO/getChamberLanguage", qs.stringify(data))
}


// 获取验证码（4位数）
const getVerifyCode4 = data => {
  return axios.post(baseUrl + '/athena-consumer/athenaUserDO/getSecurityCode', qs.stringify(data))
}

// 获取验证码（6位数）
const getVerifyCode6 = data => {
  return axios.post(baseUrl + '/athena-consumer/athenaUserDO/sendVerifyCode', qs.stringify(data))
}

// 注册
const register = data => {
  return axios.post(baseUrl + '/athena-consumer/athenaUserDO/partyRegistration', qs.stringify(data))
}

// 修改密码
const updatePassword = data => {
  return axios.post(baseUrl + '/athena-consumer/athenaUserDO/modifyPassword', qs.stringify(data))
}

export { loginIn,getChamberLanguage, getVerifyCode4, getVerifyCode6, register, updatePassword }
